import { useLocalStorage } from "@vueuse/core";
import { defineStore } from "pinia";
import { Ref, ref } from "vue";
import { LiveQueryResponse, StorePerId } from "./incidents";
import { Dayjs } from "dayjs";
import dayjs from '../plugins/dayjs';
import { db, reconnectWhenDisconnected } from "../plugins/surreal";

const STORE_NAME = "ping";
const TABLE_NAME = 'registry';

export type PingStore = {
  interval: number,
  readonly pingLabel: Ref<string>,
  pings: Readonly<Ref<{ [k: string]: Ping }>>;
  isLive: () => boolean;
  killLive: () => Promise<void>;
  live: (cb: (data: LivePing) => void) => Promise<string>;
};

export type LivePing = {
  id: string;
  result: {
    id: string;
    synced_at: string;
    live_ping: number;
  };
  action: string;
};

export type Ping = {
  label: string;
  ping: Dayjs;
  synced_at: Dayjs;
};

const usePing = defineStore(STORE_NAME, (): PingStore => {
  const pingLabel = ref<string>('laden...');
  const pings = useLocalStorage<StorePerId<Ping>>("pings", {});
  const _sessions = useLocalStorage<StorePerId<string>>("live_sessions", {});

  const interval = window.setInterval(() => {
    const firstPing: Ping | undefined = Object.values(pings.value)[0];
    if (!firstPing) {
      return;
    }

    const ping: Dayjs = (typeof firstPing.ping === 'string') ? dayjs(firstPing.ping) : firstPing.ping;
    pingLabel.value = (firstPing && ping) ? ping.fromNow() : 'laden...';
  }, 1000);

  async function killLive() {
    const table = 'registry';
    if (_sessions.value[table]) {
      try {
        await db.kill(_sessions.value[table]);
      } catch (e) {
        // e
      }
      delete _sessions.value[table];
    }
  }

  function isLive(): boolean {
    return !!_sessions.value['registry'];
  }

  async function _listenLive<T extends Record<string, unknown>>(
    cb: (data: LiveQueryResponse<T>) => void,
  ) {
    let uuid = null;
    if (_sessions.value.registry) {
      uuid = _sessions[TABLE_NAME + ""];
      console.log("Already existed ping, reusing", uuid);
    } else {
      uuid = await db.live(TABLE_NAME);
      _sessions.value[TABLE_NAME] = uuid;
      console.log("Created new ping session", uuid);
    }

    db.listenLive(uuid, cb);

    return uuid;
  }
  async function live(
    cb: (data: LivePing) => void,
  ): Promise<string> {
    reconnectWhenDisconnected();

    const uuid = await _listenLive<LivePing>(
      (data: LivePing) => {
        cb ? cb(data) : null;

        if (data.action === "CLOSE") {
          console.log("Live ping is closed");
          return;
        }

        pings.value[data.result.id] = {
          label: data.result.label || "something",
          ping: dayjs.unix(data.result.live_ping),
          synced_at: dayjs(data.result.synced_at),
        };
      },
    );

    return uuid;
  }

  return {
    live,
    pings,
    isLive,
    interval,
    killLive,
    pingLabel,
  };
});

export default usePing;

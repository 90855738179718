import { Dayjs } from "dayjs";
import { defineStore } from "pinia";
import { Ref, readonly, ref } from "vue";
import surdb, {
  QueryIncident,
  QueryIncidentAddress,
  QueryIncidentNote,
  QueryIncidentResponse,
  QueryIncidentCharacteristic,
  QueryLiveLiveIncidents,
} from "../query";
import {
  Incident as IncidentType,
  IncidentAddress,
  IncidentNote,
  IncidentResponse,
  Characteristic,
  IncidentCharacteristic,
} from "../types/incidents";
import dayjs from "../plugins/dayjs";
import useFilters from "./filters";
import { db, reconnectWhenDisconnected } from "../plugins/surreal";
import { RemovableRef, Serializer, useLocalStorage } from "@vueuse/core";

const STORE_NAME = "incidents";

// TEMP SurrealDB Types
export type LiveQueryClosureReason = "SOCKET_CLOSED" | "QUERY_KILLED";
export type LiveQueryResponse<
  T extends Record<string, unknown> = Record<string, unknown>,
> =
  | {
    action: "CLOSE";
    result?: never;
    detail: LiveQueryClosureReason;
  }
  | {
    action: "CREATE" | "UPDATE" | "DELETE";
    result: T;
    detail?: never;
  };
// END TEMP SurrealDB Types

// define store type
export type IncidentsStore = {
  isLoading: Readonly<Ref<boolean>>;
  incidents: RemovableRef<StorePerId<QueryIncident[]>>;
  notes: RemovableRef<StorePerId<IncidentNote[]>>;
  address: RemovableRef<StorePerId<IncidentAddress>>;
  responses: RemovableRef<StorePerId<IncidentResponse[]>>;
  queryIncidents: (page: number) => Promise<IncidentType[]>;
  details: (id: string) => Promise<any>;
  liveIncidents: (
    cb: (data: QueryIncident) => void,
  ) => Promise<string>;
  // liveDetails: () => Promise<string[]>;
  intialLoad: () => Promise<void>;
  isLive: (table: keyof AvailableQueries | "registry") => boolean;
  killLive: (table: keyof AvailableQueries | "registry") => Promise<void>;
};

export type IncidentDetails = {
  notes: IncidentNote[];
  address: IncidentAddress;
  responses: IncidentResponse[];
  liveNotes?: IncidentNote[][];
};

export type StorePerId<T> = { [k: string]: T };

export type LiveIncidents = {
  result: QueryIncident;
  action: string;
};

export type LiveLiveIncidents = {
  result: QueryLiveLiveIncidents;
  action: string;
};

export type AvailableQueries = {
  live_incidents: typeof QueryLiveLiveIncidents;
  incident_notes: typeof QueryIncidentNote;
  incident_responses: typeof QueryIncidentResponse;
  incident_characteristics: typeof QueryIncidentCharacteristic;
};

export const Available: AvailableQueries = {
  live_incidents: QueryLiveLiveIncidents,
  incident_notes: QueryIncidentNote,
  incident_responses: QueryIncidentResponse,
  incident_characteristics: QueryIncidentCharacteristic,
};

function customSerializer<
  T,
  K extends keyof AvailableQueries = keyof AvailableQueries,
>(type: K, writerKey: string): Serializer<T> {
  return {
    write: (v: T): string => {
      return JSON.stringify(
        Object.fromEntries(
          Object.entries(v).map(([k, v]: [string, object | object[]]) => {
            return [
              k,
              Array.isArray(v) ? v.map((v) => v[writerKey]) : v[writerKey],
            ];
          }),
        ),
      );
    },
    read: (v: string): T => {
      // JSON parse string
      const p = JSON.parse(v) || {};

      return Object.fromEntries(
        Object.entries(p).map(([k, v]: [string, object | object[]]) => {
          return [
            k,
            Array.isArray(v)
              ? v.map((v) => new Available[type](v))
              : new Available[type](v),
          ];
        }),
      ) as T;
    },
  } as Serializer<T>;
}

// Incident store with adjustence
const useIncidents = defineStore(STORE_NAME, (): IncidentsStore => {
  const isLoading = ref<boolean>(true);
  const filters = useFilters();

  const incidents = useLocalStorage<QueryIncident[]>("incidents", []);
  const notes = useLocalStorage<StorePerId<QueryIncidentNote[]>>(
    "notes",
    {},
    {
      serializer: customSerializer<QueryIncidentNote[]>(
        "incident_notes",
        "note",
      ),
    },
  );

  const address = useLocalStorage<StorePerId<IncidentAddress>>("address", {});
  const responses = useLocalStorage<StorePerId<IncidentResponse[]>>(
    "responses",
    {},
  );
  const characteristics = useLocalStorage<StorePerId<Characteristic[]>>(
    "characteristics",
    {},
  );

  /**
   * Query incidents from the database.
   */
  async function queryIncidents(
    page = 1,
    start?: number,
  ): Promise<QueryIncident[]> {
    incidents.value = await surdb.incidents(
      page,
      start,
      filters.groups,
      filters.searchTerm,
      filters.getIncidentDate(),
    );

    return incidents.value;
  }

  const liveSessions = useLocalStorage<StorePerId<string>>("live_sessions", {});

  async function listenLive<T extends Record<string, unknown>>(
    table: keyof AvailableQueries | "registry",
    cb: (data: LiveQueryResponse<T>) => void,
  ) {
    let uuid = null;
    if (liveSessions.value[table]) {
      uuid = liveSessions.value[table];
      console.log(`Already existed ${table}, reusing`, uuid);
    } else {
      uuid = await db.live(table);
      liveSessions.value[table] = uuid;
      console.log(`Created new ${table} session`, uuid);
    }

    db.listenLive(uuid, cb);

    return uuid;
  }

  async function killLive(table: keyof AvailableQueries | "registry") {
    if (liveSessions.value[table]) {
      try {
        await db.kill(liveSessions.value[table]);
      } catch (e) {
        // e
      }
      delete liveSessions.value[table];
    }
  }

  function isLive(table: keyof AvailableQueries | "registry"): boolean {
    return !!liveSessions.value[table];
  }

  // async function liveAddress(): Promise<string> {
  //   return await listenLive(
  //     "incident_address",
  //     (data: LiveQueryResponse<IncidentAddress>) => {
  //       const record = new QueryIncidentAddress(data.result);
  //
  //       address.value[record.id] = record;
  //     },
  //   );
  // }

  // async function queryLiveCollection<T, S>(
  //   table: keyof AvailableQueries,
  //   store: RemovableRef<StorePerId<S[]>>,
  // ): Promise<string> {
  //   return await listenLive(table, (data: LiveQueryResponse<T>) => {
  //     if (data.action === "CLOSE") {
  //       console.log("Live collection is closed");
  //       return;
  //     }
  //
  //     const record = new Available[table](data.result);
  //
  //     if (!store.value[record.incident_id]) {
  //       store.value[record.incident_id] = [];
  //     }
  //
  //     const index = store.value[record.incident_id]?.findIndex(
  //       (n: Available[table]) => n.id === record.id,
  //     );
  //     if (index > -1) {
  //       store.value[record.incident_id][index] = record;
  //     } else {
  //       store.value[record.incident_id].unshift(record);
  //     }
  //   });
  // }

  // async function liveDetails(): Promise<string[]> {
  //   reconnectWhenDisconnected();
  //
  //   return [
  //     await liveAddress(),
  //     await queryLiveCollection<QueryIncidentNote, IncidentNote>(
  //       "incident_notes",
  //       notes,
  //     ),
  //     await queryLiveCollection<QueryIncidentResponse, IncidentResponse>(
  //       "incident_responses",
  //       responses,
  //     ),
  //     await queryLiveCollection<QueryIncidentCharacteristic, Characteristic>(
  //       "incident_characteristics",
  //       characteristics,
  //     ),
  //   ];
  // }

  async function syncStore(storeVar: RemovableRef<any>, idVal: string | number, data: any, action: string) {
    const index = (storeVar.value || []).findIndex(
      (i: IncidentType) => i.id === idVal,
    );

    if (action === "DELETE") {
      if (index > -1) {
        incidents.value.splice(index, 1);
      }
      return;
    }

    if (index > -1) {
      storeVar.value[index] = data;
    } else {
      storeVar.value.unshift(data);
    }
  }

  /**
   * Setup live incidents and parse it into the store...
   */
  async function liveIncidents(
    cb: (data: QueryLiveLiveIncidents) => void,
  ): Promise<string> {
    reconnectWhenDisconnected();

    return await listenLive<LiveLiveIncidents>(
      "live_incidents",
      (data: LiveQueryResponse<LiveLiveIncidents>) => {
        cb && data.result !== undefined ? cb(data.result) : null;

        if (data.action === "CLOSE") {
          console.log("Live incident is closed");
          return;
        }

        const incident = new QueryLiveLiveIncidents(data.result);
        syncStore(incidents, incident.id, incident, data.action);

        syncStore(notes, incident.id, incident.notes, data.action);
        syncStore(address, incident.id, incident.address, data.action);
        syncStore(responses, incident.id, incident.incident_responses, data.action);
        syncStore(characteristics, incident.id, incident.characteristic, data.action);
      },
    );
  }

  /**
   * Quert details from the database
   */
  async function details(id: string): Promise<IncidentDetails> {
    const rs = await surdb.getIncidentDetails(id);

    const [_notes, _responses, _address, _characteristics] = rs;

    notes.value[id] = (_notes.length > 0 ? _notes : notes.value[id] || []).map(
      (n: IncidentNote) => new QueryIncidentNote(n),
    );
    responses.value[id] = (_responses || responses.value[id] || []).map(
      (r: IncidentResponse) => new QueryIncidentResponse(r),
    );
    address.value[id] = new QueryIncidentAddress(
      _address[0] || address.value[id] || {},
    );

    characteristics.value[id] = (_characteristics || characteristics.value[id] || []).map(
      (r: IncidentCharacteristic) => new QueryIncidentCharacteristic(r),
    );

    return {
      notes: notes.value[id],
      address: address.value[id],
      responses: responses.value[id],
    };
  }

  async function intialLoad() {
    const href = new URLSearchParams(document.location.search.split("?")[1]);

    await queryIncidents(parseInt(href.get("page") || "1"), 0);

    isLoading.value = false;
  }

  return {
    notes,
    address,
    responses,
    incidents,
    isLoading: readonly(isLoading),
    queryIncidents,
    characteristics,
    details,
    // liveDetails,
    liveIncidents,
    intialLoad,
    isLive,
    killLive,
  };
});

export default useIncidents;

function formatDate(createdAt: Dayjs): string {
  if (createdAt.isToday()) {
    return createdAt.format("HH:mm");
  } else if (dayjs().diff(createdAt, "day") > 6) {
    return createdAt.format("DD-MM-YYYY");
  }
  return createdAt.fromNow();
}

export function fixIncident(i: IncidentType) {
  i.start_time = dayjs(i.start_time);
  i.created_at = dayjs(i.created_at);
  i.created_format = formatDate(i.created_at);
  // in case the the time was pasted 30 minutes, it is "finished" for us..
  i.state = dayjs().diff(i.created_at, "minute") > 60 ? "finished" : i.state;

  return i;
}

// This helps adjusting the times/dates on the incident object
// and clearify some data for example adding the state.
// Maybe we can better wrap incidents into a Class with getters

export function fixerIncidents(incidents: IncidentType[]): IncidentType[] {
  return (
    incidents
      // we should fix some field types
      .map(fixIncident) as IncidentType[]
  );
}
export function fixerNotes(notes: IncidentNote[]): IncidentNote[] {
  return notes.map((n: IncidentNote) => {
    n.timestamp = dayjs(n.timestamp);
    return n;
  });
}

export function fixerResponses(
  responses: IncidentResponse[],
): IncidentResponse[] {
  return responses.map((ir: IncidentResponse) => {
    ir.start_time = dayjs(ir.start_time);
    ir.responded_at = dayjs(ir.responded_at);
    ir.alerted_at = dayjs(ir.alerted_at);

    return ir;
  });
}

import { RawQueryResult } from 'surrealdb.js/script/types';

import { db } from '../plugins/surreal';
import {
  Incident as IncidentType,
  IncidentAddress,
  IncidentNote,
  IncidentResponse,
  IncidentCharacteristic,
  Characteristic,
  UserPhoto,
} from '../types/incidents';
import dayjs, { durationFormatted } from '../plugins/dayjs';
import { Dayjs } from 'dayjs';

const LIMIT = 100;

type QuerySurrealIncident = {
  id: string;
  body: string;
  description: string;
  start_time: string;
  group_name: string;
};

type QuerySurrealLiveIncident = {
  id: string;
  body: string;
  description: string;
  start_time: string;
  group_name: string;
  characteristic: QueryIncidentCharacteristic[],
  groups: number[],
  address: QueryIncidentAddress,
  notes: QueryIncidentNote[],
  incident_responses: QueryIncidentResponse[],
};

// QueryIncident is a subset of Incident
// where the data is already formatted via Javascript
// and the _incident QuerySurrealIncident is a simplified version of Incident
export class QueryIncident {
  private _incident: QuerySurrealIncident;

  constructor(i: QuerySurrealIncident) {
    this._incident = i;
  }

  private get start_time(): Dayjs {
    return dayjs(this._incident.start_time);
  }

  get id(): string {
    return this._incident.id;
  }

  get body(): string {
    return this._incident.body;
  }

  get description(): string {
    return this._incident.description;
  }

  get date_formatted(): string {
    return this.start_time.format('DD-MM-YYYY');
  }

  get datetime_from_now(): string {
    return durationFormatted(dayjs(), this.start_time);
  }

  get ts(): number {
    return dayjs().unix() - this.start_time.unix();
  }

  get group_name(): string {
    return this._incident.group_name;
  }
}

export class QueryLiveLiveIncidents extends QueryIncident {
  private _liveIncident: QuerySurrealLiveIncident;

  constructor(i: QuerySurrealLiveIncident) {
    super(i);
    this._liveIncident = i;
  }

  get address() {
    return this._liveIncident.address;
  }

  get groups(): number[] {
    return this._liveIncident.groups;
  }

  get characteristic(): QueryIncidentCharacteristic[] {
    return this._liveIncident.characteristic;
  }

  get notes(): QueryIncidentNote[] {
    return this._liveIncident.notes;
  }

  get incident_responses(): QueryIncidentResponse[] {
    return this._liveIncident.incident_responses;
  }
}


async function incidents(
  page = 1,
  start: number | undefined = undefined,
  groups: string[] = [],
  search = '',
  incidentDate: string | null = null
): Promise<QueryIncident[]> {
  const limit = start === undefined ? LIMIT : page * LIMIT;
  const offset = start === undefined ? (page - 1) * LIMIT : start;

  let groupQuery = '1';
  if (groups.length > 0) {
    groupQuery =
      '(groups anyinside $groups OR groups.parents_id inside $groups)';
  }

  let searchQuery = '1';
  if (search.length > 0) {
    searchQuery = `(body contains $search ||
    ->incident_notes[where incident_id = $parent.id]->contents contains $search)`;
  }

  let createDate = '1';
  if (createDate !== null) {
    createDate = '1';
  }
  const query = `
    select id,
      body,
      start_time,
      description,
      start_time,
      array::min(groups.short_name) ||
      array::min(groups.name) as group_name
    from incidents
    where ${groupQuery} and ${searchQuery} and ${createDate}
    order by start_time desc
    limit ${limit} start ${offset}
  `;

  const res = await db.query<RawQueryResult<QuerySurrealIncident[]>[]>(query, {
    groups,
    search,
    incidentDate,
  });

  return res[0].map((i: QuerySurrealIncident) => new QueryIncident(i));
}

export type IncidentDetailData = [IncidentNote[], IncidentResponse[], IncidentAddress[], IncidentCharacteristic[]];

async function getIncidentDetails(incidentId: string): Promise<IncidentDetailData> {
  const res = await db.query<RawQueryResult<IncidentDetailData>[]>(
    `
    select *
    from incident_notes
    where incident_id = $iId
    order by timestamp;

    select *
    from incident_responses
    where incident_id = $iId
    order by status;

    select *
    from incident_addresses
    where incident_id = $iId;

    select *
    from incident_characterisctics
    where incident_id = $iId;
  `,
    {
      iId: incidentId,
      rawId: incidentId.replace('incidents:', 'live_incidents:'),
    }
  );

  return res as IncidentDetailData;
}

export type IncidentState = 'finished' | 'alerting';

export class QueryLiveIncidents {
  private incident: IncidentType;

  constructor(i: IncidentType) {
    this.incident = i;
  }

  get id(): string {
    return this.incident.id.toString();
  }

  get body(): string {
    return this.incident.body;
  }

  get description(): string {
    return this.incident.description;
  }

  get external_id(): string {
    return this.incident.external_id;
  }

  get groups(): number[] {
    return this.incident.groups;
  }

  // TODO: Prio should be a type
  get prio(): string {
    return this.incident.prio;
  }

  // get date_formatted(): string {
  //   return this.start_time.format('DD-MM-YYYY');
  // }
  //
  // get datetime_from_now(): string {
  //   return durationFormatted(dayjs(), this.start_time);
  // }
  // get ts(): number {
  //   return dayjs().unix() - this.start_time.unix();
  // }

  // get group_name(): string {
  //   return this.#incident.group_name;
  // }

  get start_time(): Dayjs {
    return dayjs(this.incident.start_time);
  }

  get created_at(): Dayjs {
    return dayjs(this.incident.created_at);
  }

  get state(): IncidentState {
    return dayjs().diff(this.created_at, 'minute') > 60
      ? 'finished'
      : this.incident.state;
  }

  get created_format(): string {
    return this.created_at.format('DD-MM-YYYY HH:mm');
  }

  // get notes(): QueryIncidentNote[] {
  // 	return this.incident.notes.map((n) => new QueryIncidentNote(n));
  // }
  //
  // get responses(): QueryIncidentResponse[] {
  // 	return this.incident.incident_responses.map(
  // 		(r) => new QueryIncidentResponse(r),
  // 	);
  // }
  //
  // get address(): QueryIncidentAddress {
  // 	return new QueryIncidentAddress(this.incident.address);
  // }
}

export class QueryIncidentNote {
  private note: IncidentNote;

  constructor(n: IncidentNote) {
    this.note = n;
  }

  get id(): string {
    return this.note.id;
  }

  get incident_id(): string {
    return this.note.incident_id;
  }

  get contents(): string {
    return this.note.contents;
  }

  get timestamp(): Dayjs {
    return dayjs(this.note.timestamp);
  }

  get external_id(): string {
    return this.note.external_id;
  }

  get date_formatted(): string {
    return this.timestamp.format('DD-MM-YYYY');
  }

  get datetime_from_now(): string {
    return durationFormatted(dayjs(), this.timestamp);
  }
  get ts(): number {
    return dayjs().unix() - this.timestamp.unix();
  }
}

export class QueryIncidentResponse {
  private response: IncidentResponse;

  constructor(r: IncidentResponse) {
    this.response = r;
  }

  get id(): string {
    return this.response.id;
  }

  get incident_id(): string {
    return this.response.incident_id;
  }

  get on_duty(): boolean {
    return this.response.on_duty;
  }

  get reported_status(): string {
    return this.response.reported_status;
  }

  get status(): string {
    return this.response.status;
  }

  get user_id(): number {
    return this.response.user_id;
  }

  get user_name(): string {
    return this.response.user_name;
  }

  get user_photo(): UserPhoto {
    return this.response.user_photo;
  }

  get display_order(): number {
    return this.response.display_order;
  }

  get channel(): string {
    return this.response.channel;
  }

  get responded_at(): Dayjs {
    return dayjs(this.response.responded_at);
  }

  get start_time(): Dayjs {
    return dayjs(this.response.start_time);
  }

  get alerted_at(): Dayjs {
    return dayjs(this.response.alerted_at);
  }

  get datetime_from_now(): string {
    return durationFormatted(dayjs(), this.start_time);
  }
  get ts(): number {
    return dayjs().unix() - this.start_time.unix();
  }
}

export class QueryIncidentAddress {
  private address: IncidentAddress;

  constructor(a: IncidentAddress) {
    this.address = a;
  }

  get address_type(): string {
    return this.address.address_type;
  }

  get city(): string {
    return this.address.city;
  }

  get country(): string {
    return this.address.country;
  }

  get formatted_address(): string {
    return this.address.formatted_address;
  }

  get house_number(): string {
    return this.address.house_number;
  }

  get latitude(): number {
    return this.address.latitude;
  }

  get longitude(): number {
    return this.address.longitude;
  }

  get postcode(): string {
    return this.address.postcode;
  }

  get street_name(): string {
    return this.address.street_name;
  }
}

export class QueryIncidentCharacteristic {
  private characteristic: Characteristic;

  constructor(c: Characteristic) {
    this.characteristic = c;
  }

  get id(): string {
    return this.characteristic.id;
  }
  get incident_id(): string {
    return this.characteristic.incident_id;
  }

  get name(): string {
    return this.characteristic.name;
  }

  get values(): string {
    return this.characteristic.values[0];
  }
}

export default {
  incidents,
  getIncidentDetails,
};

import dayjs, { Dayjs } from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';
import isToday from 'dayjs/plugin/isToday';
import duration from 'dayjs/plugin/duration';

dayjs.extend(relativeTime);
dayjs.extend(updateLocale);
dayjs.extend(duration);
dayjs.extend(isToday);

dayjs.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s geleden',
    s: '%d seconden',
    m: 'een minut',
    mm: '%d minuten',
    h: 'een uur',
    hh: '%d uren',
    d: 'een dag',
    dd: '%d dagen',
    M: 'een maand',
    MM: '%d maanden',
    y: 'een jaar',
    yy: '%d jaren',
  },
});

export default dayjs;

export function durationFormatted(ts1: Dayjs, ts2: Dayjs) {
  const diffi = dayjs.duration(ts1.diff(ts2));

  const hours = diffi.hours();
  const minutes = diffi.minutes();
  const seconds = diffi.seconds();

  if (hours === 0 && minutes === 0) {
    return `${seconds}s`;
  }
  if (hours === 0) {
    return diffi.format('mm:ss') + ' geleden';
  }

  return ts2.format('HH:mm');
}

import {
	RemovableRef,
	StorageSerializers,
	useLocalStorage,
} from "@vueuse/core";
import { defineStore } from "pinia";
import { readonly, ref, Ref } from "vue";
import { User as SurrealUser } from "../types/surreal";
import { Surreal } from "surrealdb.js";
import { connect, db } from "../plugins/surreal";

const STORE_NAME = "auth";

export type AuthStore = {
	readonly isLoggedin: Ref<boolean | undefined>;
	readonly isLoading: Ref<boolean>;
	readonly isConnected: Ref<boolean>;
	user: Readonly<RemovableRef<SurrealUser | null>>;
	doSignin: (username: string, password: string) => Promise<boolean>;
	logout: () => Promise<void>;
};

const useAuth = defineStore(STORE_NAME, (): AuthStore => {
	const isLoggedin = ref<boolean>(); // leave empty
	const isLoading = ref<boolean>(true);
	const isConnected = ref<boolean>(false);
	const user = useLocalStorage<SurrealUser | null>(STORE_NAME, null, {
		serializer: StorageSerializers.object,
	});
	// const groups = useLocalStorage(STORE_NAME + "|groups", []);

	async function checkSession() {
		isLoading.value = true;
		const res = await fetch(`${import.meta.env.VITE_AUTH_SERVER}/user`, {
			headers: {
				"Content-Type": "application/json",
			},
			credentials: "include",
		}).catch(() => null);

		if (res === null || res.status !== 200) {
			isLoading.value = false;
			isLoggedin.value = false;
			return;
		}

		const { token: token_, display_name, email, id } = await res.json();

		user.value = {
			display_name,
			email,
			id,
		};

		// connect to surreal, otherwise we can't do anything
		connect({}, async (db: Surreal) => {
			isLoggedin.value = await db.authenticate(token_);
		})
			.then((connected: boolean) => isConnected.value = connected)
			.catch((err: Error) => {
				console.log("CONNECTED errro");
				isConnected.value = false;
				console.log("DB error", err);
			})
			.finally(() => {
				isLoading.value = false;
			});
	}

	// send signin request to Firebase
	async function doSignin(email: string, password: string): Promise<boolean> {
		isLoading.value = true;

		const res = await fetch(`${import.meta.env.VITE_AUTH_SERVER}/login`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			credentials: "include",
			body: JSON.stringify({
				email,
				password,
			}),
		});

		if (res.status !== 200) {
			isLoading.value = false;
			return false;
		}

		checkSession();

		return true;
	}

	async function logout() {
		await db.invalidate();

		user.value = null;
		isLoggedin.value = false;
		isConnected.value = false;
	}

	checkSession();

	return {
		isLoading,
		isLoggedin,
		isConnected: readonly(isConnected),
		user: readonly(user),
		doSignin,
		logout,
	};
});

export default useAuth;

<script setup lang="ts">
import { computed, useSlots } from 'vue';
import { useLink } from 'vue-router';

const props = defineProps<{ to: object }>();
const link = useLink(props);
const slots = useSlots();
const isActive = computed<boolean>(
  () => link.isExactActive.value || link.isActive.value
);
</script>

<template>
  <li class="me-2">
    <router-link :to="props.to" class="inline-flex items-center justify-center py-2 px-4 group" :class="{
      'text-blue-500 active': isActive,
      'hover:bg-gray-50 dark:hover:bg-gray-800': !isActive,
    }">
      <slot name="icon" :iconClass="[
        'mb-1',
        {
          'text-blue-500': isActive,
          'text-gray-500 dark:text-gray-400 group-hover:text-blue-600 dark:group-hover:text-blue-500':
            !isActive,
        },
      ]" />

      <span class="text-sm" :class="{
        'text-gray-500 dark:text-gray-400 group-hover:text-blue-600 dark:group-hover:text-blue-500':
          !isActive,
        'text-blue-500': isActive,
        'pl-2': !!slots['icon']
      }">
        <slot />
      </span>
    </router-link>
  </li>
</template>

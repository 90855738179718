import { Surreal } from "surrealdb.js";
import { ConnectionOptions } from "surrealdb.js/script/types";

const HOST = import.meta.env.VITE_SURREAL_HOST || "ws://localhost:8000";

const DefaultOptions: ConnectionOptions = {
  namespace: import.meta.env.VITE_SURREAL_NS || "brw",
  database: import.meta.env.VITE_SURREAL_DB || "incidents_db",
};

export enum WebsocketStatus {
  OPEN = 0,
  CLOSED = 1,
  RECONNECTING = 2,
}

export type PrepareFn = (db: Surreal) => Promise<void>;

const db: Surreal = new Surreal({
  onConnect() {
    console.log("Surreal: Connected to host");
  },
  onClose(err) {
    console.log("Surreal: closed connection to host", err);
  },
  onError(err) {
    console.log("Surreal: on error", err);
  },
});

// async function reconnect(): Promise<void> {
//   db.socket?.open();
// }

// Setup the connection to the database
// and prepare the database with the prepare function.
async function connect(
  opts: ConnectionOptions,
  prepare: PrepareFn,
): Promise<boolean> {
  async function prepareFn(db: Surreal) {
    // console.log("preparing database", db.socket?.open());
    //@ts-ignore
    // db.connection.socket.addEventListener("error", (err: Error) => {
    // 	console.log("Catch error", err);
    // });
    // db.connection.socket.addEventListener("close", (a, b) => {
    // 	console.log("Catch close", a, b);
    // });
    await prepare?.(db);
  }

  await db.connect(HOST, {
    ...opts,
    namespace: DefaultOptions.namespace,
    database: DefaultOptions.database,
    prepare: prepareFn,
  });

  // Deprecated in 1.0.x
  //await db.wait();

  return true;
}

// Send a to the database to check if it's still alive
// and to keep the connection alive.
async function dbPing(): Promise<void> {
  reconnectWhenDisconnected();

  const res = await db.ping();

  return res;
}

async function reconnectWhenDisconnected(): Promise<void> {
  // console.log(db.status, 'db status');
  if (db.status === WebsocketStatus.CLOSED) {
    console.log("reconnecting to database");
    //@ts-ignore
    db.socket?.open();
  }
}

export {
  db,
  connect,
  dbPing,
  reconnectWhenDisconnected,
  DefaultOptions as options,
};

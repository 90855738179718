<script setup lang="ts">
const props = withDefaults(defineProps<{ width?: number; height?: number }>(), {
  width: 100,
  height: 100,
});

const style = {
  '--fire-width': props.width + 'px',
  '--fire-height': props.height + 'px',
};
</script>

<template>
  <div>
    <div :style="style" class="fire-wrapper">
      <div class="fire">
        <div class="fire-left">
          <div class="main-fire"></div>
          <div class="particle-fire"></div>
        </div>
        <div class="fire-center">
          <div class="main-fire"></div>
          <div class="particle-fire"></div>
        </div>
        <div class="fire-right">
          <div class="main-fire"></div>
          <div class="particle-fire"></div>
        </div>
        <div class="fire-bottom">
          <div class="main-fire"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.fire-wrapper.safe-space {
  padding: 40% 10% 3% 14%;
}
</style>

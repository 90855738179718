<script setup lang="ts">
import Fire from './Fire.vue';
const version = import.meta.env.PROD ? __APP_VERSION__ ?? 'dev' : 'dev';
const buildDatetime = import.meta.env.PROD
  ? __BUILD_DATE__ ?? 'development'
  : 'development';
</script>

<template>
  <div class="flex flex-col justify-center px-4 w-full">
    <Fire />
    <div class="pt-2 mt-2 text-gray-600 font-bold text-lg text-center">
      Incident App
      <span class="app__version">{{ version }}</span>
    </div>
    <div class="pt-2 text-gray-500 text-center">
      Application state is being loaded...
    </div>
    <slot />

    <footer
      class="fixed left-4 right-4 bottom-0 text-center text-gray-500 text-xs pb-safe"
    >
      <span class="app__build-datetime">{{ buildDatetime }}</span>
    </footer>
  </div>
</template>

<script setup lang="ts">
import { useOneSignal } from '@onesignal/onesignal-vue3';
import { ref, watch, Transition, onMounted, provide, onUnmounted } from 'vue';
import { useRoute, type RouteLocation } from 'vue-router';
import TabItem from './components/TabItem.vue';
import AuthGuard from './components/AuthGuard.vue';
import useIncidents from './stores/incidents';
import usePing from './stores/ping';
import dayjs from './plugins/dayjs';
import DashboardSvg from './assets/dashboard.svg?component';
import IncidentsSvg from './assets/incidents.svg?component';
import ProfileSvg from './assets/profile.svg?component';

const ping = usePing();
const route = useRoute();
// const store = useIncidents();
const onesignal = useOneSignal();

// const intervalMS = 60 * 60 * 1000;
// const updateServiceWorker = useRegisterSW({
//   onRegistered(r) {
//     r &&
//       window.setInterval(() => {
//         console.log('Hitting update interval')
//         r.update();
//       }, intervalMS);
//   },
// });

const latestUpdate = ref<string>('laden...');
provide('latestPing', latestUpdate);

// const interval = window.setInterval(() => {
//   const firstPing = Object.values(store.pings)[0];
//   if (!firstPing) {
//     return;
//   }
//
//   const ping = typeof firstPing.ping === 'string' ? dayjs(firstPing.ping) : firstPing.ping;
//   latestUpdate.value = firstPing && ping ? ping.fromNow() : 'laden...';
// }, 1000);

onUnmounted(() => {
  clearInterval(ping.interval);
});

const transition = ref<string>('slide-right');
watch(() => route,
  (to: RouteLocation, from: RouteLocation) => {
    const toDepth = to.path.split('/').length;
    const fromDepth = from.path.split('/').length;

    transition.value = toDepth < fromDepth ? 'slide-right' : 'slide-left';
  }
);

onMounted(async () => {
  // const permission = await onesignal.
  // console.log('Notifications permission is ', permission);
  // console.log('enabled', await onesignal.isPushNotificationsEnabled());

  console.log(onesignal.Notifications.permissionNative);
  console.log(onesignal.Notifications.isPushSupported());

  onesignal.Notifications.addEventListener(
    'permissionPromptDisplay',
    (e: Event) => {
      console.info('permissionPromptDisplay', e);
    }
  );
  onesignal.Notifications.addEventListener(
    'getNotificationPermission',
    (e: Event) => {
      console.info('getNotificationPermission', e);
    }
  );
  onesignal.Notifications.addEventListener(
    'isPushNotificationsSupported',
    (e: Event) => {
      console.info('isPushNotificationsSupported', e);
    }
  );
  onesignal.Notifications.addEventListener(
    'notificationDisplay',
    (e: Event) => {
      console.info('notificationDisplay', e);
    }
  );
  onesignal.Notifications.addEventListener(
    'notificationDismiss',
    (e: Event) => {
      console.info('notificationDismiss', e);
    }
  );
});
</script>

<template>
  <AuthGuard>
    <div class="flex app-mobile bg-gray-100">
      <div class="h-full w-full overflow-y-auto flex flex-col" data-page-container>
        <router-view v-slot="{ Component, route }">
          <Transition :name="route.meta.transition">
            <component :is="Component" />
          </Transition>
        </router-view>
      </div>

      <div class="app-navigation bg-white border rounded-lg border-gray-200 dark:bg-gray-700 dark:border-gray-600">
        <ul class="flex -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400">
          <TabItem :to="{ name: 'home' }">
            Dashboard
            <template #icon="{ iconClass }">
              <DashboardSvg :class="['w-4 h-4', iconClass]" />
            </template>
          </TabItem>

          <TabItem :to="{ name: 'incidents' }">
            Incidenten
            <template #icon="{ iconClass }">
              <IncidentsSvg :class="['w-4 h-4', iconClass]" />
            </template>
          </TabItem>

          <TabItem :to="{ name: 'profile' }">
            Profiel
            <template #icon="{ iconClass }">
              <ProfileSvg :class="['w-4 h-4', iconClass]" />
            </template>
          </TabItem>
        </ul>
      </div>
    </div>
  </AuthGuard>
</template>

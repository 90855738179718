<script setup lang="ts">
import { computed, ref, watch, onMounted } from 'vue';
import useAuth from '../stores/auth';
import useIncidents from '../stores/incidents';
import { dbPing } from '../plugins/surreal';
import SplashScreen from '../components/SplashScreen.vue';
import Fire from '../components/Fire.vue';
import { useDocumentVisibility } from '@vueuse/core';
import { QueryLiveIncidents } from '../query';
import usePing, { LivePing } from '../stores/ping';

const auth = useAuth();
const ping = usePing();
const incidents = useIncidents();
const email = ref<string>('');
const password = ref<string>('');
const loginError = ref<boolean>(false);

const visibility = useDocumentVisibility();

enum AppVisibility {
  VISIBLE = 'visible',
  HIDDEN = 'hidden',
  PRERENDER = 'prerender',
}

const disableStuff = computed<boolean>(() => {
  return !email.value || email.value.length < 5 || !password.value;
});

onMounted(() => {
  email.value = window.localStorage.getItem('email') || '';
});

watch(
  () => visibility.value,
  (value) => {
    if (value === AppVisibility.VISIBLE) {
      console.log('App visible');
      dbPing()
        .then(() => console.log('Ping success!'))
        .catch((err) => console.log('Ping failed!', err));

      if (!ping.isLive()) connectLivePing();
      if (!incidents.isLive('live_incidents')) connectLiveIncident();
    } else {
      console.log('App hidden');
      ping.killLive();
      incidents.killLive('live_incidents');
    }
  }
);

// TODO: do we want to do this once??
watch(
  () => auth.isLoggedin && auth.isConnected,
  async (isLoggedin) => {
    if (!isLoggedin) {
      ping.killLive();
      incidents.killLive('live_incidents');

      return;
    }

    window.localStorage.setItem('email', email.value);

    connectLivePing();
    connectLiveIncident();

    // Ready to initially load data from the database..
    incidents.intialLoad();

    // onesignal.init({
    //   appId: import.meta.env.VITE_ONESIGNAL_KEY,
    //   // safari_web_id: import.meta.env.VITE_ONESIGNAL_SAFARI,
    //   serviceWorkerParam: { scope: '/push/onesignal/' },
    //   serviceWorkerPath: '/push/onesignal/OneSignalSDKWorker.js',
    //   persistNotification: true,
    //   // allowLocalhostAsSecureOrigin: true
    //   // subdomainName?: string;
    //   // requiresUserPrivacyConsent?: boolean;
    //   // promptOptions?: object,
    //   // welcomeNotification?: object;
    //   // notifyButton?: object;
    //   // webhooks?: object;
    //   // autoResubscribe?: boolean;
    //   // autoRegister?: boolean;
    //   // notificationClickHandlerMatch?: string;
    //   // notificationClickHandlerAction?: string;
    //   // serviceWorkerParam?: {
    //   //     scope: string;
    //   // };
    //   // serviceWorkerPath?: string;
    //   // serviceWorkerUpdaterPath?: string;
    //   // path?: string;
    //   // allowLocalhostAsSecureOrigin?: boolean;
    // });
  }
);

async function login() {
  if (disableStuff.value) return;

  loginError.value = false;
  if (!(await auth.doSignin(email.value.toLowerCase(), password.value))) {
    loginError.value = true;
  }
}

async function connectLivePing() {
  // let timeout = true;
  // setTimeout(() => {
  //   if (timeout) {
  //     ping.killLive();
  //     incidents.killLive('live_incidents');
  //
  //     // reset all connections
  //     connectLivePing();
  //     connectLiveIncident();
  //   }
  // }, 5000);

  try {
    await ping.live((data: LivePing) => {
      // timeout = false;
      if (data.action === 'CLOSE') {
        // remove because it's closed
        ping.killLive();

        if (visibility.value === AppVisibility.VISIBLE) {
          console.log('reconnecting live ping');
          connectLivePing();
        }
      }
    });
  } catch (err) {
    console.error('Failed to connect live ping', err);
  }
}

async function connectLiveIncident() {
  await incidents.liveIncidents((data: QueryLiveIncidents) => {
    console.log('incident', data);
    if (data.action === 'CLOSE') {
      // remove because it's closed
      incidents.killLive('live_incidents');
      if (visibility.value === AppVisibility.VISIBLE) {
        console.log('reconnecting live incident');
        connectLiveIncident();
      }
    }
  });
}
</script>

<template>
  <div v-if="!auth.isLoggedin && !auth.isLoading" class="p-4 w-full">
    <div class="flex flex-col justify-center px-4 my-14">
      <Fire />
    </div>
    <div v-if="loginError"
      class="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800"
      role="alert">
      <svg class="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
        fill="currentColor" viewBox="0 0 20 20">
        <path
          d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
      </svg>
      <span class="sr-only">Info</span>
      <div>
        <span class="font-medium">Login failed!</span> Not logged in, please try
        again.
      </div>
    </div>

    <div class="grid gap-6 mb-6 md:grid-cols-2">
      <div>
        <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email</label>
        <input type="text" id="email"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="your@email.com" v-model="email" @keyup.enter="login" required />
      </div>
      <div>
        <label for="password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Password</label>
        <input type="password" id="password"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Doe" required v-model="password" @keyup.enter="login" />
      </div>

      <button type="submit"
        class="text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        :class="{
          'opacity-50 cursor-not-allowed': disableStuff,
        }" @click="login" :disabled="disableStuff">
        Submit
      </button>
    </div>
  </div>

  <SplashScreen class="h-full" v-else-if="auth.isConnected === false || auth.isLoading || incidents.isLoading
    " />

  <slot v-else />
</template>

import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import router from "./router";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginVue from "@bugsnag/plugin-vue";
import OneSignalVuePlugin from "@onesignal/onesignal-vue3";

let bs = null;
if (import.meta.env.VITE_BUGSNAG_KEY) {
  Bugsnag.start({
    apiKey: import.meta.env.VITE_BUGSNAG_KEY,
    plugins: [new BugsnagPluginVue()],
    appVersion: __APP_VERSION__ ?? 'dev',
  });
  bs = Bugsnag.getPlugin("vue");
}

if ("serviceWorker" in navigator && import.meta.env.MODE === "production") {
  // && import.meta.env.PROD
  // importScripts('sw.js');
  // importScripts('https://cdn.onesignal.com/sdks/OneSignalSDKWorker.js');
  // console.log('registered serviceWorker');
  window.addEventListener("load", () => {
    navigator.serviceWorker
      .register("/push/onesignal/OneSignalSDKWorker.js", {
        scope: "/push/onesignal/",
      })
      .catch((err: Error) => {
        console.error(
          "something went wrong while registering onesignal service-worker:",
          err,
        );
      });

    navigator.serviceWorker
      .register(
        import.meta.env.MODE === "production" ? "/sw.js" : "/dev-sw.js?dev-sw",
        { type: import.meta.env.MODE === "production" ? "classic" : "module" },
      )
      .catch((err: Error) => {
        console.error(
          "something went wrong while registering the App service-worker:",
          err,
        );
      });
  });
}

const app = createApp(App);
const pinia = createPinia();
if (bs) app.use(bs);
app.use(router);
app.use(pinia);
app.use(OneSignalVuePlugin, {
  appId: import.meta.env.VITE_ONESIGNAL_KEY,
  safari_web_id: import.meta.env.VITE_ONESIGNAL_SAFARI,
  serviceWorkerParam: { scope: "/push/onesignal/" },
  serviceWorkerPath: "/push/onesignal/OneSignalSDKWorker.js",
  allowLocalhostAsSecureOrigin: true,
});
app.mount("#app");

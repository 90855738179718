import dayjs from '../plugins/dayjs';
import { defineStore } from 'pinia';
import { ref, Ref } from 'vue';

const today = dayjs();
const STORE_NAME = 'incident_filters';

export type FiltersForm = {
  groups: string[];
  incidentDate: string[];
  searchTerm: string;
};

export type AuthStore = {
  groups: Ref<string[]>;
  incidentDate: Ref<string[]>;
  searchTerm: Ref<string>;
  toggleGroup: (group: string) => void;
  submitForm: (form: FiltersForm) => void;
  toggle: (show: boolean) => void;
  show: Ref<boolean>;
  getIncidentDate: () => string | null;
};

const useFilters = defineStore(STORE_NAME, (): AuthStore => {
  const groups = ref<string[]>([]);
  const incidentDate = ref<string[]>(
    dayjs().format('DD-MM-YYYY').split('-').reverse()
  );
  const searchTerm = ref<string>('');
  const show = ref<boolean>(false);

  function toggleGroup(group: string): void {
    if (groups.value?.includes(group)) {
      groups.value.slice(
        groups.value.findIndex((g) => g === group),
        1
      );
    } else {
      groups.value.push(group);
    }
  }

  function submitForm(form: FiltersForm) {
    groups.value = form.groups;
    incidentDate.value = form.incidentDate;
    searchTerm.value = form.searchTerm;
  }

  function toggle(tggl: boolean) {
    show.value = tggl;
  }

  function getIncidentDate(): string | null {
    const fmt = incidentDate.value.join('-');
    if (fmt !== today.format('YYYY-MM-DD')) {
      return fmt;
    }

    return null;
  }

  return {
    groups,
    toggleGroup,
    submitForm,
    incidentDate,
    searchTerm,
    show,
    toggle,
    getIncidentDate,
  };
});

export default useFilters;

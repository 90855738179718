import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/Dashboard.vue'),
  },
  {
    path: '/incidents',
    children: [
      {
        path: '',
        name: 'incidents',
        component: () => import('../views/incidents/Overview.vue'),
      },
      {
        path: '/:id',
        name: 'incidents.show',
        component: () => import('../views/incidents/Show.vue'),
      },
    ],
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('../views/Profile.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});
//
// router.afterEach((to, from) => {
//   if (
//     ['home', 'profile'].includes(to.name) ||
//     ['home', 'profile'].includes(from.name)
//   ) {
//     return;
//   }
//   const toDepth = to.path.split('/').length;
//   const fromDepth = from.path.split('/').length;
//
//   to.meta.transition =
//     toDepth < fromDepth ? 'van-slide-right' : 'van-slide-left';
// });

export default router;
